import { Badge, Button, Grid, makeStyles } from "@material-ui/core"
import { connect } from 'react-redux';
import system_translations from "../../../texts/system_translations";
import UserInfo from "./components/UserInfo"
import usePharmaceuticalsDetailsView from './usePharmaceuticalsDetailsView'
import PasswordModal from './modals/PasswordModal/PasswordModal';
import EditUserModal from './modals/EditUserModal/EditUserModal';
import Page from "../../../components/Structure/Layouts/Page";
import ProfilePictureModal from "./modals/ProfilePictureModal/ProfilePictureModal";
import PharmaCard from "./components/PharmaCard";
import PieChartOrders from "./components/PieChartOrders";
import { blueGrey } from "@material-ui/core/colors";
import cx from 'classnames'
import ToolbarTable from "./components/ToolbarTable";
import PharmaGeneralView from "./views/PharmaGeneralView.jsx/PharmaGeneralView";

const menuOptions = [
    {id:1, label:'General',},
    {id:2, label:'Reporte'},
]

const PharmaceuticalsDetailsView = ({language, history, user, match}) => {

    const classes = useStyles()
    const content = system_translations[language].views.user

    const {view_data, actions, modals, system} = usePharmaceuticalsDetailsView({history, user, match})

    let selectedView = null

    if(view_data.view === 1) selectedView = <PharmaGeneralView 
    pharma={view_data.pharma} onEdit={() => actions.onUpdateModal('edit_user', true)}/>


    return(
        <Page title={"Detalle de farmacéutica"} setReturn loading={system.loading} >   
            <ProfilePictureModal open={modals.change_avatar} master_actions={actions} 
            user={view_data.profile_view ? user : view_data.selected_user}
            onClose={() => actions.onUpdateModal('change_avatar',false)} />
            <EditUserModal origin={view_data.pharma} open={modals.edit_user} history={history} actions={actions} view_data={view_data}
            onClose={() => actions.onUpdateModal('edit_user', false)} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PharmaCard data={view_data.pharma}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PieChartOrders data={view_data.pharma}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <div>
                        <ToolbarTable selected={view_data.view} onChange={actions.onChangeView} menuOptions={menuOptions} />
                    </div>
                    <div>
                        {selectedView}
                    </div>
                </Grid>

            </Grid>

        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language:state.language, 
    }
}

export default connect(mapStateToProps)(PharmaceuticalsDetailsView)

const useStyles = makeStyles(theme => ({
    edit_button:{
        background:theme.palette.primary.main,
        borderRadius:20,
        boxShadow:'0px 2px 7px rgba(0, 0, 0, 0.31)',
        textTransform:'none',
        color:'white',
        padding:'8px 28px'
    },
    delete_button:{
        background:'white',
        color:theme.palette.primary.main,
        boxShadow:'0px 5px 7px rgba(0, 0, 0, 0.12)'
    }
    
}))