import React, {useState, useEffect} from 'react';
import { alpha, AppBar, Button, Grid, makeStyles, Typography, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { general_translations as translations_esp } from '../../../texts/esp/general-translations'

import cx from 'classnames'
import { grey } from '@material-ui/core/colors';
import { DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import { onClearMemory } from '../../../shared/utility';
import UserButton from '../../../components/Actions/UserButton';

const Topbar = ({user, content, history, is_auth, cart, onUpdateSignInModal, onUpdateUserData, onUpdateAuthStatus}) => {

    const classes = useStyles()
    //const content = translations_esp.topbar
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const trigger = useScrollTrigger({disableHysteresis:true, threshold:100})


    const onItemSelected = async(url) => {
        switch (url) {
            case '/signin':
                onUpdateSignInModal(true)
                break;
            case '/logout':
                onUpdateUserData(null)
                onUpdateAuthStatus(false)
                onClearMemory()
                history.push('/signin')
                break;
        
            default:
                history.push(url)
                break;
        }
    }

    return ( 
        <AppBar className={classes.appbar}>
            <div className={classes.root}>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item xs></Grid>
                    <Grid item>
                        <UserButton user={user} content={content.user_button} onItemSelected={onItemSelected} />
                    </Grid>
                </Grid>
            </div>
        </AppBar>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        //width:1400,
        marginLeft:DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        padding:'12px 24px',
        margin:'auto',
        boxSizing:'border-box',
        position:'relative',
        width:`calc(100% - ${DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH}px )`,
        [theme.breakpoints.down('md')]:{
            width:'100%',
            padding:'0px 16px'
        },
    },
    button:{
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        textTransform:'none',
        padding:'8px 16px',
        borderRadius:32,
        fontWeight:700
    },
    appbar:{
        background:alpha("#FFF",0.62),
        boxShadow:'none',
        //background:grey[400],
        //background:'transparent',
        color:'black',
        //boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        height:76,
        borderBottom:'2px solid #EBEBEB'
        //color:'grey',
    },
    appbar_mono:{
        //background:'transparent',
        
    },
    logo:{
        width:48
    },
}))

const mapStateToProps = state => {
    return {
        user:state.user,
        is_auth:state.is_auth,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSignInModal: (signin_modal) => dispatch({type:actionTypes.MODAL_UPDATE_SIGNIN, signin_modal}),
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        

    }
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)( Topbar));
