import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetDateFormat, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import { useStyles } from './styles'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'

import { private_server } from '../../../../../config'
import InputDateForm from '../../../../../components/Forms/InputDateForm'


const EditOrderModal = props => {

    const { open, onClose, origin, view_data, actions, onRequestUsers, history, parameter } = props

    const classes = useStyles()
    const [form, setForm] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            //setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            if(parameter){
                let _formParam = {[parameter]:JSON.parse(JSON.stringify(formData[parameter]))}
                console.log(_formParam)
                let _form = onInitForm(_formParam, origin);
                setForm(_form)
            }
            //let _formParam = JSON.parse(JSON.stringify(formData[parameter]))
            //let _form = onInitForm(_formParam, origin);
            //_form.pharma_id.options = [...view_data.pharmaceuticals]
            if(origin){
                //_form.status_id.value = origin.status_id === 1
                
                //_form.birth_date.value = onGetDateFormat(origin.birth_date, 'YYYY-MM-DD')
            }
            //setForm(_form)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, origin)
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.patch(`/order/pharma/me/${origin.id_order}`, data2send)
            await actions.onUpdateUsers()
            actions.onUpdateModalStatus('edit_parameter', false)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }

    }

    let title = ''
    let contentView = null
    if(form && parameter && origin && open){
        console.log(form)
        const temp = JSON.stringify(form)
        if(temp !== "{}"){
            if(form[parameter]){
                title = form[parameter].config.label
                const type = form[parameter].config.type
                switch(type){
                    case 'select':
                        contentView = <Grid item xs={12} >
                            <InputSelectForm data={form[parameter]} onChange={onChange} />
                        </Grid>
                    break;
                    case 'date':
                        contentView = <Grid item xs={12} >
                            <InputDateForm data={form[parameter]} onChange={onChange} />
                        </Grid>
                    break;
                    default:
                        contentView = <Grid item xs={12} >
                            <InputForm data={form[parameter]} onChange={onChange} />
                        </Grid>
                }
            }
        }
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>{title}</Typography>
                <Typography variant='body1' color='textSecondary' >Por favor ingresa los datos solicitados</Typography>
                <div className={classes.form}>
                <Grid container spacing={3}>
                    {contentView}
                </Grid>
                    {/*
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.compendium_key} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.external_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.exercise_year} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.order_applicant_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.order_event_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.units} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.amount} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.contract_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.replacement_order_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.clue} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'style={{marginBottom:16}}>Datos de entrega</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <InputSelectForm data={form.order_delivery_type_id} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.delivery_institution} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.delivery_entity} onChange={onChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'style={{marginBottom:16}}>Fechas</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <InputDateForm data={form.order_reception_date} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputDateForm data={form.order_expiration_date} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputDateForm data={form.delivery_date} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputDateForm data={form.collection_entry_date} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputDateForm data={form.payment_date} onChange={onChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    */}
                </div>
                <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Guardar
                </RoundedButton>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default EditOrderModal


const formData = {
    compendium_key: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'compendium_key',
            type: 'text',
            fullWidth: true,
            label: 'Clave de compendio',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    exercise_year: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[
            {value:"2021", label:'2021'},
            {value:"2022", label:'2022'},
        ],
        config: {
            id: 'exercise_year',
            type: 'select',
            fullWidth: true,
            label: 'Año de ejercicio',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'select',
        }
    },
    order_applicant_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.applicants],
        config: {
            id: 'order_applicant_id',
            type: 'select',
            fullWidth: true,
            label: 'Año de ejercicio',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'select',
        }
    },
    event_identifier: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'event_identifier',
            type: 'text',
            fullWidth: true,
            label: 'ID del evento',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    order_event_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.orders_events],
        config: {
            id: 'order_event_id',
            type: 'select',
            fullWidth: true,
            label: 'Evento',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'select',
        }
    },
    order_status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.orders_statuses],
        config: {
            id: 'order_status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estatus',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'select',
        }
    },
    invoiced: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.orders_invoiced],
        config: {
            id: 'invoiced',
            type: 'select',
            fullWidth: true,
            label: '¿Facturado?',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'select',
        }
    },
    units: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'units',
            type: 'number',
            fullWidth: true,
            label: 'Unidades adjudicadas',
            helperText: 'Este campo debe de ser mayor a 0'
        },
        rules: {
            type: 'numeric',
            min: 1, max: 999999
        }
    },
    contract_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contract_id',
            type: 'text',
            fullWidth: true,
            label: 'P.O. (Contrato)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    replacement_order_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'replacement_order_id',
            type: 'text',
            fullWidth: true,
            label: 'Orden de reposición',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    amount: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'amount',
            type: 'number',
            fullWidth: true,
            label: 'Monto',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'numeric',
            min: 0.00001, max: 99999999999999999999
        }
    },

    /// OPTIONALS/////////////////////////
    clue: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'clue',
            type: 'text',
            fullWidth: true,
            label: 'CLUE',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    external_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'external_id',
            type: 'text',
            fullWidth: true,
            label: 'ID externo',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    /////////////////////////  DELIVERY ///////////////////////////////////
    order_delivery_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.orders_delivery_types],
        config: {
            id: 'order_delivery_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de entrega',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'select',
        }
    },
    delivery_institution: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'delivery_institution',
            type: 'text',
            fullWidth: true,
            label: 'Instituto de entrega',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    delivery_entity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'delivery_entity',
            type: 'text',
            fullWidth: true,
            label: 'Entidad de entrega',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    /////////////////////////  DATES ///////////////////////////////////
    order_reception_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'order_reception_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de recepción pedido',
            helperText: 'Fecha no válida'
        },
        rules: {
            type: 'date',
        }
    },
    order_expiration_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'order_expiration_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de vencimiento pedido',
            helperText: 'Fecha no válida'
        },
        rules: {
            type: 'date',
        }
    },
    delivery_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'delivery_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de entrega',
            helperText: 'Fecha no válida'
        },
        rules: {
            type: 'date',
        }
    },
    collection_entry_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'collection_entry_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de ingreso a cobro',
            helperText: 'Fecha no válida'
        },
        rules: {
            type: 'date',
        }
    },
    payment_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'payment_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de pago',
            helperText: 'Fecha no válida'
        },
        rules: {
            type: 'date',
        }
    },
    
}