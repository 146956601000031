import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { blue, blueGrey, grey } from '@material-ui/core/colors';
import React from 'react';

const divsize = 85

const CircularProgressBar = ({progress}) => {

    const classes = useStyles()

    let percentage = 0
    if(progress.total !== 0) percentage = Math.ceil((progress.completed/progress.total) * 100)

    return ( 
        <div className={classes.root}>  
            <Typography variant='subtitle1' color='textSecondary' >Progreso de cumplimiento</Typography>
            <Typography variant='h3' style={{color:blue[900]}}>{`${percentage}%`}</Typography>
        {/*
            <CircularProgress value={percentage} variant='static' size={divsize} className={classes.top}/>
            <CircularProgress value={100} variant='static' size={divsize} className={classes.bottom}/>
        <Typography className={classes.label}>{`${percentage}`}<sup style={{fontSize:16}}>%</sup></Typography>*/}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        //width:divsize, height:divsize
    },
    bottom:{
        position:'absolute', top:0, left:0, zIndex:1,
        color:grey[400]
    },
    top:{
        position:'absolute', top:0, left:0, zIndex:2,
        color:blue[700]
    },
    label:{
        position:'absolute', top:'50%', left:'50%', zIndex:3,
        transform:'translate(-50%, -50%)',
        fontSize:28,
        fontWeight:600,
        color:blueGrey[700]
    }
}))
 
export default CircularProgressBar;