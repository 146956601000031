import React from 'react'
import { Button, CircularProgress, Fab, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles, Typography, LinearProgress } from '@material-ui/core'
import { isPharma, onFormatNumber, onGetCurrencyValue, onGetDateFormat, onGetFullname, onGetPaymentDays, onGetTimeDuration } from '../../../../shared/utility'
import { blueGrey, grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import FilterButton from './FilterButton'
import ActionBar from './ActionBar'
import empty from '../../../../assets/icons/empty.png'
import { catalogs } from '../../../../texts/esp/catalogs'
import GenericStatus from '../../../../components/Structure/DisplayData/GenericStatus'
import { nodata, USER_TYPES } from '../../../../config'
import BalanceProgressBar from '../../../../components/Structure/DisplayData/BalanceProgressBar'


const statusCatalog = [{id:-1, value:-1, label:'Todos'}, {id:1, value:1, label:'Activo'},{id:0, value:0, label:'Inactivo'}]
const searchCatalog = [{id:1, value:'first_name', label:'Nombre '},{id:2, value:'username', label:'Usuario'}]

const master_header = [
    {id:1, label:'ID'},
    {id:100, label:'Empresa'},
    {id:3, label:'Clave de compendio'},
    {id:4, label:'Año'},
    {id:5, label:'Solicitante'},
    {id:6, label:'Evento'},
    {id:7, label:'ID del Evento'},
    {id:8, label:'P.O./Contrato'},
    {id:9, label:'Orden de reposición'},
    {id:17, label:'Unidades adjudicadas'},
    {id:18, label:'Monto'},
    {id:15, label:'Estatus'},
    {id:10, label:'Tipo de entrega'},
    {id:11, label:'Instituto de entrega'},
    {id:12, label:'CLUE'},
    {id:2, label:'ID Externo'},
    {id:13, label:'Fecha de recepción'},
    {id:14, label:'Fecha de de vencimiento'},
    {id:16, label:'¿Facturado?'},
    
    {id:20, label:'Fecha de entrega'},
    {id:21, label:'Fecha de ingreso'},
    {id:22, label:'Fecha de pago'},
    {id:23, label:'Días totales de pago'},
    {id:24, label:'Acciones'},
]

const pharma_header = [
    {id:1, label:'ID'},
    
    {id:3, label:'Clave de compendio'},
    {id:4, label:'Año'},
    {id:5, label:'Solicitante'},
    {id:6, label:'Evento'},
    {id:7, label:'ID del Evento'},
    {id:8, label:'P.O./Contrato'},
    {id:9, label:'Orden de reposición'},
    {id:17, label:'Unidades adjudicadas'},
    {id:18, label:'Monto'},
    {id:15, label:'Estatus'},
    {id:10, label:'Tipo de entrega'},
    {id:11, label:'Instituto de entrega'},
    {id:12, label:'CLUE'},
    {id:2, label:'ID Externo'},
    {id:13, label:'Fecha de recepción'},
    {id:14, label:'Fecha de de vencimiento'},
    {id:16, label:'¿Facturado?'},
    
    {id:20, label:'Fecha de entrega'},
    {id:21, label:'Fecha de ingreso'},
    {id:22, label:'Fecha de pago'},
    {id:23, label:'Días totales de pago'},
    {id:24, label:'Acciones'},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    addIcon:{
        background:theme.palette.primary.main,
        borderRadius:'50%',
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16,
        //border:'1px solid red'
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, task_type, filter, master, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, year, onRemoveItem, onChangeSearch, onAddRegister, onDeleteItem} = props

    //console.log(data)
    const ispharma = isPharma(master)
    const header = ispharma ? pharma_header : master_header

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} alignItems='center' > 
                    <Grid item xs/>
                    <Grid item><SearchBar value={filter.query} onChange={onChangeSearch}/></Grid>
                    <Grid item> <IconButton className={classes.addIcon} color='primary' onClick={onAddRegister}><Icon >add</Icon></IconButton> </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs={12}><LinearProgress/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <Typography variant='subtitle2' style={{fontSize:14,color:'#A9A9A9', fontWeight:500}} >{item.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {id_order, compendium_key, order_event, rfc, order_status_id, amount, units_paid, units, pharma, delivery_date, payment_date, total_amount, exercise_year, contract_id, replacement_order_id,
                                        paid_amount, external_id, order_applicant, event_identifier, order_delivery_type, delivery_institution, clue, order_expiration_date, order_reception_date, invoiced, collection_entry_date} = item

                                    const _amount = onGetCurrencyValue(amount, 2)
                                    const _paid_amount = onGetCurrencyValue(paid_amount, 2)

                                    const _order_reception_date = onGetDateFormat(order_reception_date, 'DD / MM / YYYY')
                                    const _order_expiration_date = onGetDateFormat(order_expiration_date, 'DD / MM / YYYY')

                                    const _delivery_date = onGetDateFormat(delivery_date, 'DD / MM / YYYY')
                                    const _collection_entry_date = onGetDateFormat(collection_entry_date, 'DD / MM / YYYY')
                                    const _payment_date = onGetDateFormat(payment_date, 'DD / MM / YYYY')

                                    const days_left = onGetPaymentDays(delivery_date, payment_date)
                                    const _invoiced = invoiced === 1 ? 'Si' : 'No'


                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{id_order}</Typography></TableCell>
                                            {!ispharma ? <TableCell className={classes.cell} onClick={() => onSelectedItem(item)} style={{minWidth:200}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{pharma}</Typography></TableCell> : null}
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'compendium_key')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{compendium_key}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'exercise_year')}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{exercise_year ? exercise_year : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'order_applicant_id')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{order_applicant ? order_applicant : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'order_event_id')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{order_event ? order_event : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'event_identifier')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{event_identifier ? event_identifier : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'contract_id')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{contract_id ? contract_id : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'replacement_order_id')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{replacement_order_id ? replacement_order_id : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'units')} style={{minWidth:100}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{units ? units : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'amount')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{_amount ? _amount : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'order_status_id')} style={{minWidth:120}}><GenericStatus text_styles={{color:'#425C7E',fontWeight:600}} bold value={order_status_id} catalogs={catalogs.orders_statuses}/></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'order_delivery_type_id')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{order_delivery_type ? order_delivery_type : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'delivery_institution')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{delivery_institution ? delivery_institution : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'clue')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{clue ? clue : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'external_id')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{external_id ? external_id : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'order_reception_date')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{_order_reception_date ? _order_reception_date : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'order_expiration_date')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{_order_expiration_date ? _order_expiration_date : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'invoiced')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{_invoiced}</Typography></TableCell>
                                            
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'delivery_date')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{_delivery_date ? _delivery_date : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'collection_entry_date')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{_collection_entry_date ? _collection_entry_date : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'payment_date')} style={{minWidth:150}}><Typography variant='subtitle1' style={{color:'#425C7E'}}>{_payment_date ? _payment_date : nodata}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)} style={{minWidth:120}}><Typography variant='subtitle1' style={{padding:'0px 16px',borderRadius:4,color:'black', background:days_left.color, display:'inline-block'}}>{days_left.days}</Typography></TableCell>
                                            <TableCell className={classes.cell}  style={{minWidth:10}}>
                                                <Grid container spacing={1} wrap='nowrap'>
                                                    {/*<Grid item>
                                                        <IconButton size='small' color='primary' ><Icon>edit</Icon></IconButton>
                                                    </Grid>*/}
                                                    <Grid item>
                                                        <IconButton size='small' onClick={() => onDeleteItem(item)} ><Icon>delete</Icon></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <Typography>No se encontraron registros</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable