import { useState, useEffect } from "react"
import { updateAuthorizationHeader } from "../../../shared/utility"
import { private_server, public_server } from "../../../config"
import { generic_errors } from "../../../texts/esp/genericErrors"
import queryString from 'query-string'

const useAdministratorsView = () => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [selectedItem, setSelectedItem] = useState(null)
    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [filter, tableFilter])

    const onGetParams = () => {
        const {limit, offset} = tableFilter

        let andFiltersArgs = [
            //{"field":"name","operator":"LIKE","":"%%"},
        ]

        let finalArgs = {
            "AND":andFiltersArgs
        }

        const param2send = {limit:limit, offset:limit*offset, order_by:'id_pharma', order:0}
        let params = `?${queryString.stringify(param2send)}`
        if(andFiltersArgs.length) params = `${params}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`

        return params
    }
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/pharma/all${_params}`)
                const temp = users_request.data.data
                setTotal(temp.count)
                setData(temp.pharmas)
                //console.log(temp.pharmas)

            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onUpdateUsers: async() => {
            setSending(true)
            try {
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/pharma/all${_params}`)
                const temp = users_request.data.data
                setTotal(temp.count)
                setData(temp.pharmas)
            } catch (error) {
                console.log(error)
            }
            setSending(false)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onChangeFilter: (data) => setFilter(data)
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, filter, selectedItem}

    return {system, actions, view_data, modals}
}

export default useAdministratorsView