import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actionTypes } from '../../../../../store/actions';
import SimpleTable from './components/SimpleTable';
import AddUserModal from './modals/AddUserModal/AddUserModal';
import EditUserModal from './modals/EditUserModal/EditUserModal';
import usePharmaUsersSecion from './usePharmaUsersSecion';


const PharmaUsersSecion = ({pharma, history}) => {

    //const content = translations_esp
    const {system, view_data, actions, modals } = usePharmaUsersSecion({pharma})

    return ( 
        <div style={{marginTop:32}}>
                <AddUserModal open={modals.add_register} onSubmit={actions.onSubmit} actions={actions} pharma={pharma}
                onClose={() => actions.onUpdateModalStatus('add_register', false)}/>
                <EditUserModal origin={view_data.selected_user} open={modals.edit_register} history={history} actions={actions} view_data={view_data}
            onClose={() => actions.onUpdateModalStatus('edit_register', false)} />
                <div>
                    <SimpleTable 
                        loading={system.loading || system.sending}
                        data={view_data.data} 
                        filter={view_data.filter}
                        tableFilter={view_data.tableFilter}
                        total={view_data.total} 
                        loading={system.loading}
                        onSelectedItem={actions.onEditUser}
                        onAddRegister={() => actions.onUpdateModalStatus('add_register', true)}
                        onUpdateTableFilter={actions.onUpdateTableFilter}
                        onChangeFilter={actions.onChangeFilter} />
                </div>
        </div>  
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(PharmaUsersSecion);