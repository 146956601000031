import React from 'react';
import { Drawer, alpha, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions';
import cx from 'classnames';
import { grey } from '@material-ui/core/colors';
import { withRouter } from 'react-router';
import system_translations from '../../../texts/system_translations';
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS } from '../../../config';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';

const Sidebar = ({drawer_status, history, ismobile, language, user}) => {

    
    const classes = useStyles()

    const isURLSelected = (path) => {
        return history.location.pathname.split('/')[1] === path.split('/')[1];
    }

    const onChangeView = (url) => {
        history.push(url)
    }

    const content = system_translations[language].general.sidebar

    let user_level = 1;

    if(user) user_level = user.user_type_id ? user.user_type_id : 100 
    console.log(user_level)

    return ( 
        <div>
            <Drawer open={drawer_status} 
                variant={ismobile ? 'temporary' : 'persistent'}
                classes={{paper:classes.drawer}}
            >
                <div className={classes.container}>
                    <div className={classes.logowrapper}>
                        <div className={classes.logo_container}>
                            <img src={content.logo} alt='' className={classes.logo}/>
                        </div>
                    </div>
                    
                    <div className={classes.menu}>
                        <Grid container>
                            {content.list.map((item,key)=>{

                                if(!((user_level >= item.min_level) && (user_level <= item.max_level) )) return null

                                const isactive = isURLSelected(item.path)

                                return(
                                    <Grid item xs={12} key={key.toString()}>
                                        <SidebarItem active={isactive} {...item} onChangeView={onChangeView}/>
                                    </Grid>
                                )
                            })} 
                        </Grid>
                        
                    </div>
                </div>
                
            </Drawer>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    drawer:{
        background:theme.palette.primary.dark,
        width:DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        borderTopRightRadius:24,
        borderBottomRightRadius:24,
        border:0,
        boxShadow:'2px 4px 15px rgba(0, 0, 0, 0.14)',
    },
    logo:{
        width:'100%',
        height:'100%',
        padding:8,
        boxSizing:'border-box',
        //objectFit:'cover',
        position:'absolute',
        top:0, left:0
    },
    logowrapper:{
        background:'white'
    },
    logo_container:{
        
        width:100,
        height:100,
        margin:'auto',
        //borderRadius:'50%',
        boxSizing:'border-box',
        background:'white',
        position:'relative'
    }, 
    container:{
        //width:150,
        padding:'0px 0px'
    },
    title:{
        color:'white',
        marginTop:8
    },
    menu:{
        margin:'32px 0px'
    }
}))

const mapStateToProps = state => {
    return {
        drawer_status:state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));


const SidebarItem = ({active, name, path, image, onChangeView}) => {

    const classes = useStylesItem()

    return(
        <div className={cx(classes.root,
        {[classes.active]:active
        })} onClick={() => onChangeView(path)}>
            {active ? <div className={classes.decoration}/> : null}
            <Grid container justifyContent='center'>
                <Grid item>
                    <img src={image} width={32}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography align='center' variant='body2' style={{fontSize:14, fontWeight: active ? 600 : 500}}>{name}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

const useStylesItem = makeStyles(theme => ({
    root:{
        color:grey[300],
        padding:'16px 8px',
        marginTop:8, 
        position:'relative',
        '&:hover':{
            background:alpha("#FFF",0.25),
            cursor:'pointer',
            transition: theme.transitions.create(['background'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        
    },
    active:{
        background:alpha("#FFF",0.25),
        borderTopRightRadius:16,
        borderBottomRightRadius:16,
        '&:hover':{
            background:alpha("#FFF",0.25),
            cursor:'pointer'
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    decoration:{
        position:'absolute',
        top:0, left:0,
        width:6,
        height:'100%',
        background:'white',
        borderRadius:16
    }
}))