import React, { useState, useEffect } from 'react';
import { Grid, Icon, IconButton, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import order_icon from '../../../../../../assets/icons/box.png'
import RoundedButton from '../../../../../../components/Actions/RoundedButton';

const ValidationView = ({files2create, files2update, progress, sending, onClose, onSubmitRequest}) => {

    const classes = useStyles()

    return ( 
        <div>
            <Grid container alignItems='center'>
                <Grid item xs>
                    <Typography variant='h6'>Archivo procesado exitosamente</Typography>
                </Grid>
            </Grid>
            
            <div style={{marginTop:32}}>
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <img src={order_icon} width={120}/>
                            </Grid>
                        </Grid>
                    
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <OrderDetail data={files2create} title='Órdenes nuevas'/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <OrderDetail data={files2update} title='Órdenes por actualizar'/>
                    </Grid>
                </Grid>
            </div>
            {sending ? <div className={classes.progress}>
                <Typography align='center' variant='h5'>{`${progress} %`}</Typography>
                <LinearProgress />
            </div> : null}

            <div className={classes.button_container}>
                <RoundedButton color='grey' onClick={onClose}  >Cancelar</RoundedButton>
                <div style={{padding:16}}/>
                <RoundedButton onClick={onSubmitRequest}>Actualizar</RoundedButton>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    progress:{
        marginTop:32
        //display:'flex',
        //flexDirection:'column',
    },
    button_container:{
        marginTop:32,
        display:'flex',
        justifyContent:'center'
        //flexDirection:'column',
    }
}))
 
export default ValidationView;

const OrderDetail = ({data, title}) => {

    const classes = useOrderDetailStyles()

    return(
        <Grid container justifyContent='center' direction='column'>
            <Grid item>
                <Typography align='center' variant='subtitle2'>{title}</Typography>
            </Grid>
            <Grid item>
                <Typography align='center' className={classes.number} >{data ? data.length : 0}</Typography>
            </Grid>
        </Grid>
    )
}

const useOrderDetailStyles = makeStyles(theme => ({
    number:{
        fontSize:32
    }
}))