import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { onGetErrorMessage, updateAuthorizationHeader } from '../../../../../../shared/utility';
import SimpleModal from '../../../../../../components/Modals/SimpleModal';
import ActionModalBar from '../../../../../../components/Actions/ActionModalBar';
import InputFile from './InputFile'
import XLSX from 'xlsx'
import { excel_template, ORDER_MATRIX, ORDER_MATRIX_CAT, private_server } from '../../../../../../config';
import { catalogs } from '../../../../../../texts/esp/catalogs';
import ValidationView from './ValidationView';
import moment from 'moment';

//import { request_timereport, request_upload_document } from '../requests';

const UploadDocumentModal = ({id, open, onClose, onUpdateData, actions}) => {

    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [processed, setProcessed] = useState(false)
    const [files2update, setFiles2Update] = useState([])
    const [files2create, setFiles2Create] = useState([])
    const [progress, setProgress] = useState(0)
    const [sending, setSending] = useState(false)

    useEffect(() => {
        if(!open){
            setFile(null)
            setLoading(false)
            setSending(false)
            setProgress(0)
            setFiles2Update([])
            setFiles2Create([])
            setProcessed(false)
        }
    },[open])

    const onSubmit = async() => {try {
        if(!file){
            setError('Debe adjuntar un documento')
            return
        }
        setLoading(true)
        const arrayBuffer = await file.arrayBuffer();
        const uint8 = new Uint8Array(arrayBuffer)
        const workbook = XLSX.read(uint8, { type: 'array' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        var data = XLSX.utils.sheet_to_json(worksheet, {header:1});

        //let header = data[0]
        let data2update = []
        let data2create = []

        const header = []
        for(let i = 0; i<excel_template.length; i++) header.push(excel_template[i].name)
        console.log(header)
        for(let i=1; i<data.length;i++){
            const temp = {}
            const current = data[i]
            for(let j=0; j<header.length;j++){
                const col = excel_template[j]
                const identifier = col.key
                const cat_key = col.cat_key
                //console.log(cat_key)
                let current_value = null
                if(cat_key){
                    const options = catalogs[col.cat]
                    const current_temp = current[j] !== null && current[j] !== undefined ? current[j].toString() : ''
                    const element =  options.find(el => el.label.toLowerCase().trim() === current_temp.toLowerCase().trim())
                    if(element){
                        current_value = element.value   
                        if(current_value !== "" && current_value !== null && current_value !== undefined ) temp[cat_key] = current_value
                    }
                }else{
                    if(current[j] !== "" && current[j] !== null && current[j] !== undefined ){
                        if(identifier.includes('date')){
                            if(Number.isInteger(current[j])){
                                var date = new Date(Math.round((current[j] - (25567 + 1)) * 86400 * 1000));
                                //console.log(identifier,date.toISOString().split('T')[0])
                                let finalvalue = date.toISOString().split('T')[0]
                                
                                finalvalue = moment(finalvalue).subtract('1','d')
                                if(finalvalue.isValid()){
                                    temp[identifier] = finalvalue.format('YYYY-MM-DD')
                                }
                            }else{
                                const _date = moment(current[j] )
                                if(_date.isValid()) temp[identifier] = _date.format('YYYY-MM-DD')
                                
                            }
                        }else{
                            temp[identifier] = current[j] 
                        }
                        
                    }
                    
                }
            }
            if(temp.compendium_key){
                if(temp.id_order){
                    data2update.push(temp)
                }else{
                    data2create.push(temp)
                }
            }
            
        }
        setFiles2Update(data2update)
        setFiles2Create(data2create)
        setProcessed(true)
        console.log('--------------------------')
        console.log(data2update)
        console.log(data2create)

    } catch (error) {
        console.log(error)
        //setError(onGetErrorMessage(error))   
    } setLoading(false)}

    const onCancelFiles = () => {
        if(sending) return;
        setFiles2Create([])
        setFiles2Update([])
        setProcessed(false)
        setSending(false)
    }

    const onSubmitRequest = async() => {

        if(sending) return

        const total = files2create.length + files2update.length
        let count = 0;
        let data2send

        
        setSending(true)
        updateAuthorizationHeader(private_server)
        try {
            // Files to update
            console.log('Updating files...')
            for(let i=0; i<files2update.length; i++){
                data2send = {...files2update[i]}
                const orderID = data2send.id_order
                delete data2send.id_order

                // Independent try catch for this register
                try {
                    await private_server.patch(`/order/pharma/me/${orderID}`, data2send)                    
                } catch (error) {
                    console.log(`There was an error with the order ${orderID}`)
                }
                count++;
                setProgress(parseInt((count/total)*100))
                
            }

            // Files to create
            console.log('Creating files...')
            for(let i=0; i<files2create.length; i++){
                data2send = {...files2create[i]}
                //const orderID = data2send.id_order
                delete data2send.id_order

                // Independent try catch for this register
                try {
                    await private_server.post(`/order/pharma/me `, data2send)                    
                } catch (error) {
                    console.log(`There was an error with the order ${i}`)
                }
                count++;
                setProgress(parseInt((count/total)*100))
                
            }

            await actions.onUpdateUsers()
            await actions.onUpdateModalStatus('upload_document', false)

        } catch (error) {
            console.log(error)
        }
        setSending(false)
        setProcessed(false)


    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <>
                {!processed ? <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h6' >Subir archivo</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputFile id='file' file={file} label='Archivo de órdenes' 
                            onChange={(file) => setFile(file)} onDelete={() => setFile(null)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar btnLabel='Procesar' loading={loading} error={error} onSubmit={onSubmit}/>
                    </Grid>
                </Grid> : null}
                {processed ? <div>
                    <ValidationView files2create={files2create} files2update={files2update} onClose={onCancelFiles}
                    progress={progress} sending={sending} onSubmitRequest={onSubmitRequest}/> 
                </div> : null}
            </>
        </SimpleModal>
     );
}
 
export default UploadDocumentModal;