import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import { useStyles } from './styles'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import { private_server } from '../../../../../config'
import InputDateForm from '../../../../../components/Forms/InputDateForm'


const EditPaymentModal = props => {

    const { open, onClose, origin, view_data, actions, onRequestUsers, history, order_id } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            let _form = onInitForm(form, origin);
            //_form.pharma_id.options = [...view_data.pharmaceuticals]
            if(origin){
                //_form.status_id.value = origin.status_id === 1
                
                //_form.birth_date.value = onGetDateFormat(origin.birth_date, 'YYYY-MM-DD')
            }
            setForm(_form)
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        //data2send.order_id = parseInt(order_id)  
        setLoading(true)
        try {
            console.log(data2send)
            updateAuthorizationHeader(private_server)
            await private_server.patch(`/orderpayment/${origin.id_order_payment}`, data2send)
            await actions.onRefreshData()
            await actions.onRefreshPaymentData()
            actions.onUpdateModal('edit_payment',false)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
            <Typography variant='h6'  style={{ fontWeight: 600 }}>Editar pago</Typography>
                <Typography variant='body1' color='textSecondary' >Por favor ingresa los datos solicitados</Typography>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.units} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.amount} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputDateForm data={form.payment_date} onChange={onChange} />
                        </Grid>
                        
                    </Grid>
                </div>
                <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Agregar
                </RoundedButton>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default EditPaymentModal


const formData = {
    units: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'units',
            type: 'number',
            fullWidth: true,
            label: 'Unidades',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'numeric',
            min: 1, max: 99999999999999999999
        }
    },
    amount: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'amount',
            type: 'number',
            fullWidth: true,
            label: 'Monto',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'numeric',
            min: 0, max: 9999999999999999999999
        }
    },
    payment_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'payment_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de pago',
            helperText: 'Fecha no válida'
        },
        rules: {
            type: 'date',
        }
    },
}