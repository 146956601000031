import React from 'react'
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DatePicker,
  } from '@material-ui/pickers';
import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core';
import moment from 'moment'
import {ruleValidation} from './customFunctions.jsx';

const InputDateForm = props => {    

    const {onChange, data, disabled} = props
    const {value, isVisited, isValid} = props.data
    const {label,placeholder, helper} = props.data.config


    const handleDateChange = (date) => {
      //console.log('Cambio')
      //console.log(date)
      //console.log(date.format('YYYY-MM-DD'))
        const response =date.format('YYYY-MM-DD');
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    }


    const error = isVisited && !isValid;
    //console.log(value)


    return(
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    disabled={disabled}
                    value={value}
                    placeholder={placeholder}
                    error={error}
                    fullWidth
                    variant='inline'
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label={label}
                    helperText={error ? helper : null}
                    onChange={handleDateChange}
                    clearable
                    autoOk
                    
                />
            </MuiPickersUtilsProvider>
            
        </div>
    )
}

export default InputDateForm