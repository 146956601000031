import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../components/Structure/DisplayData/VerticalData';
import { nodata } from '../../../../../config';
import PharmaUsersSecion from '../../components/PharmaUsersSecion/PharmaUsersSecion';


const PharmaGeneralView = ({pharma, onEdit}) => {

    const classes = useStyles()

    let name = nodata
    let rfc = nodata
    let email = nodata
    let mobile = nodata

    if(pharma){
        name = pharma.name ? pharma.name : nodata
        rfc = pharma.rfc ? pharma.rfc : nodata
        email = pharma.email ? pharma.email : nodata
        mobile = pharma.mobile ? pharma.mobile : nodata
    }

    return ( 
        <div className={classes.root}>
            <SimpleCard>
                <div className={classes.container2}>
                    <Typography variant='h6' style={{marginBottom:24}}>Detalles</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <VerticalData
                            label='Nombre'
                            data={name}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='RFC'
                            data={rfc}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Direcció de correo electrónico'
                            data={email}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Celular'
                            data={mobile}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={onEdit} >Editar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </SimpleCard>
            <div>
                <PharmaUsersSecion pharma={pharma} />
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'32px 0px'
    }
}))
 
export default PharmaGeneralView;