import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AdministratorDetailsView from '../views/Administrators/AdministratorDetailsView/AdministratorDetailsView'
import AdministratorsView from '../views/Administrators/AdministratorsView/AdministratorsView'
import OrderDetailsView from '../views/Orders/OrderDetailsView/OrderDetailsView'
import OrdersView from '../views/Orders/OrdersView/OrdersView'
import PharmaceuticalsDetailsView from '../views/Pharmaceuticals/PharmaceuticalsDetailsView/PharmaceuticalsDetailsView'
import PharmaceuticalsView from '../views/Pharmaceuticals/PharmaceuticalsView/PharmaceuticalsView'
import UserDetailsView from '../views/Users/UserDetailsView/UserDetailsView'
import UsersView from '../views/Users/UsersView/UsersView'

const DashboardRouter = props => {
    return(
        <Switch>
            <Route exact path='/' component={AdministratorsView} />
            <Route exact path='/admins/:id' component={AdministratorDetailsView} />
            <Route exact path='/admins' component={AdministratorsView} />
            <Route exact path='/users/:id' component={UserDetailsView} />
            <Route exact path='/users' component={UsersView} />
            <Route exact path='/pharmaceuticals/:id' component={PharmaceuticalsDetailsView} />
            <Route exact path='/pharmaceuticals' component={PharmaceuticalsView} />
            <Route exact path='/orders/:id' component={OrderDetailsView} />
            <Route exact path='/orders' component={OrdersView} />
            <Redirect to='/orders' />
        </Switch>
    )
}

export default DashboardRouter

