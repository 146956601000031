import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import { useStyles } from './styles'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import { private_server } from '../../../../../config'


const AddUserModal = props => {

    const { open, onClose, view_data, actions, onRequestUsers, history, pharmas, master } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            //console.log(pharmas)
            let _form = JSON.parse(JSON.stringify(formData))
            //_form.pharma_id.options = [...pharmas]
            setForm(_form)
            setError(''); setLoading(false);
        }
        let _form = JSON.parse(JSON.stringify(formData))
        //_form.pharma_id.options = [...pharmas]
        setForm(_form)
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            console.log(errors)
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        data2send.pharma_id = master.id_user
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.post('/order/pharma/me', data2send)
            await actions.onUpdateUsers()
            actions.onUpdateModalStatus('add_register', false)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            
        }
        setLoading(false)

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>Agregar orden</Typography>
                <Typography variant='body1' color='textSecondary' >Por favor ingresa los datos solicitados</Typography>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.compendium_key} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.exercise_year} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.order_applicant_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelectForm data={form.order_event_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.units} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.amount} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.contract_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.replacement_order_id} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Agregar
                </RoundedButton>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default AddUserModal


const formData = {
    compendium_key: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'compendium_key',
            type: 'text',
            fullWidth: true,
            label: 'Clave de compendio',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    exercise_year: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[
            {value:"2021", label:'2021'},
            {value:"2022", label:'2022'},
        ],
        config: {
            id: 'exercise_year',
            type: 'select',
            fullWidth: true,
            label: 'Año de ejercicio',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'select',
        }
    },
    order_applicant_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.applicants],
        config: {
            id: 'order_applicant_id',
            type: 'select',
            fullWidth: true,
            label: 'Solicitante',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'select',
        }
    },
    order_event_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.orders_events],
        config: {
            id: 'order_event_id',
            type: 'select',
            fullWidth: true,
            label: 'Evento',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'select',
        }
    },
    units: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'units',
            type: 'number',
            fullWidth: true,
            label: 'Unidades adjudicadas',
            helperText: 'Este campo debe de ser mayor a 0'
        },
        rules: {
            type: 'numeric',
            min: 1, max: 999999
        }
    },
    contract_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contract_id',
            type: 'text',
            fullWidth: true,
            label: 'P.O. (Contrato)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    replacement_order_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'replacement_order_id',
            type: 'text',
            fullWidth: true,
            label: 'Orden de reposición',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    amount: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'amount',
            type: 'number',
            fullWidth: true,
            label: 'Monto',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'numeric',
            min: 0.00001, max: 99999999999999999999
        }
    },

}