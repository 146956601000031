import moment from "moment"
import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import {  updateAuthorizationHeader } from "../../../shared/utility"

const usePharmaceuticalsDetailsView = ({user, history, match}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [profile_view, setProfileView] = useState(false)

    const [error, setError] = useState(null)

    const [users, setUsers] = useState([])
    const [total, setTotal] = useState(10)

    const [selected_user, setSelectedUser] = useState(null)

    const [query, setQuery] = useState('')
    const [pharma, setPharma] = useState(null)

    const [view, setView] = useState(1)


    const [modals, setModals] = useState({
        add_user:false,
        edit_user:false,
        change_password:false,
        change_avatar:false,
        add_pharma_user:false,
        edit_pharma_user:false
    })

    useEffect(() => {
        if(user) actions.onInitModule()
    }, [user])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                let user_id = match.params.id
                const _user = await private_server.get(`/pharma/${user_id}`)
                console.log(_user.data.data.pharma)
                setPharma(_user.data.data.pharma)
                setLoading(false)
                
            } catch (error) {
                console.log(error)
            }
        },
        onRefreshData: async() => {
            try {
                let user_id = match.params.id
                const _user = await private_server.get(`/pharma/${user_id}`)
                console.log(_user.data.data.pharma)
                setPharma(_user.data.data.pharma)
            } catch (error) {
                console.log(error)
            }
        },
        onUpdateUsers: async() => {
            try {
                let user_id = match.params.id
                const _user = await private_server.get(`/pharma/${user_id}`)
                console.log(user)
                setPharma(_user.data.data)
            } catch (error) {
                
            }
            
        },
        onUpdateQuery:(data) => {
            setQuery(data)
        },
        onSelectedItem: () => {

        },
        onSelectedItem: async(data) => {
            console.log('Disparar busqueda')
            const _user = await private_server.get(`/user/by/${data._id}`)
            console.log(user)
            setSelectedUser(_user.data.data)
            actions.onUpdateModal({edit_user:true})
        },
        onChangeFilter: () => {

        },
        onUpdateUser: (data) => {
            setSelectedUser(data)
        },
        onUpdateModal: (_key, _value) => {
            let _modals = {...modals, [_key]:_value}
            setModals(_modals)
        },
        onChangeView: (_view) => {
            setView(_view)
        }

    }

    const system = {loading, sending, error}
    const view_data = {users, pharma, query, total, profile_view, view}

    return {system, modals, view_data, actions}
}

export default usePharmaceuticalsDetailsView