import React, { useState, useEffect } from 'react';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import { alpha, Button, Grid, Icon, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { nodata } from '../../../../config';
import { makeStyles } from '@material-ui/styles';
import { isValidDocument, onGetCurrencyValue, onGetDateFormat } from '../../../../shared/utility';
import GenericStatus from '../../../../components/Structure/DisplayData/GenericStatus';
import { catalogs } from '../../../../texts/esp/catalogs';
import { grey } from '@material-ui/core/colors';


const header = [
    {id:1, label:'ID'},
    {id:2, label:'Unidades'},
    {id:3, label:'Monto'},
    {id:4, label:'Fecha de pago'},
    {id:5, label:'Evidencia'},
]

const PaymentsCard = ({data, onSelectedItem, onUploadDocument, onDownloadDocument, onAddRegister}) => {

    const classes = useStyles()

    return (  
        <div>
            <div style={{marginBottom:8}}>
                <Grid container alignItems='baseline'>
                    <Grid item xs>
                        <Typography variant='h6'>Historial de pagos</Typography>
                    </Grid>
                    <Grid item>
                        <Button className={classes.add_button} onClick={onAddRegister}>
                            <Icon>add</Icon>
                            Agregar
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <SimpleCard padding='none'>
                <div className={classes.root}>
                    {data && data.length ? (
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography variant='subtitle2' style={{fontSize:14,color:'#A9A9A9', fontWeight:500}} >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {id_order_payment, units, payment_status_id, receipt, order_status_id, amount, units_paid, pharma, delivery_date, payment_date, paid_amount} = item

                                        const _amount = onGetCurrencyValue(amount, 0)
                                        const _delivery_date = onGetDateFormat(delivery_date, 'DD / MM / YYYY')
                                        const _payment_date = onGetDateFormat(payment_date, 'DD / MM / YYYY')

                                        const valid = isValidDocument(receipt)

                                        return(
                                            <TableRow key={key.toString()} className={classes.row} >
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2' style={{color:'#425C7E'}}>{id_order_payment}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2' style={{color:'#425C7E'}}>{units}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2' style={{color:'#425C7E'}}>{_amount}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2' style={{color:'#425C7E'}}>{_payment_date}</Typography></TableCell>
                                                <TableCell className={classes.cell} padding='checkbox' >
                                                    <Grid container wrap='nowrap' alignItems='center'>
                                                        <Grid item>
                                                            <IconButton color='primary' onClick={() => onUploadDocument(item)}>
                                                                <Icon>cloud_upload</Icon>
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton color='primary' disabled={!valid} onClick={() => onDownloadDocument(item)}>
                                                                <Icon>cloud_download</Icon>
                                                            </IconButton>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </div>
                        
                    ): null}
                </div>
            </SimpleCard>
        </div>
        

    );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'0px 0px'
    },
    title:{
        fontWeight:700,
        fontSize:18,
        color:"#425C7E",
        width:200,
        textAlign:'center'
    },
    add_button:{
        background:'#1C63BF',
        color:'white',
        textTransform:'none',
        fontFamily:theme.typography.fontFamily,
        fontSize:16,
        borderRadius:40,
        padding:'8px 24px',
        '&:hover':{
            background:alpha('#1C63BF',0.9),
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:8,
        paddingBottom:8
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
}))
 
export default PaymentsCard;