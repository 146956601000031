import moment from "moment"
import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import {  onGetCatalogs, updateAuthorizationHeader } from "../../../shared/utility"
import axios from 'axios'
import FileSaver from 'file-saver';
const useOrderDetailsView = ({user, history, match}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [profile_view, setProfileView] = useState(false)

    const [error, setError] = useState(null)

    const [users, setUsers] = useState([])
    const [total, setTotal] = useState(10)

    const [selected_user, setSelectedUser] = useState(null)

    const [query, setQuery] = useState('')
    const [order, setOrder] = useState(null)
    const [pharmaceuticals, setPharmaceuticals] = useState([])
    const [payments, setPayments] = useState([])
    const [selected_payment, setSelectedPayment] = useState(null)

    const [modals, setModals] = useState({
        add_payment:false,
        edit_payment:false,
        edit_user:false,
        change_password:false,
        change_avatar:false,
        upload_evidence:false,
    })

    useEffect(() => {
        if(user) actions.onInitModule()
    }, [user])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                let order_id = match.params.id
                const _order = await private_server.get(`/order/pharma/me/${order_id}`)
                setOrder(_order.data.data.order)
                const pharma_request = await private_server.get(`/pharma/all`)
                setPharmaceuticals(onGetCatalogs(pharma_request.data.data.pharmas,'id_pharma','name'))
                let finalArgs = {"AND":[{"field":"order_id","operator":"=","value":order_id}]}
                const _payment = await private_server.get(`/orderpayment/all?filter=${encodeURIComponent(JSON.stringify(finalArgs))}`)
                setPayments(_payment.data.data.order_payments)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onRefreshData: async() => {
            try {
                let user_id = match.params.id
                const _order = await private_server.get(`/order/pharma/me/${user_id}`)
                console.log(_order.data.data.order)
                setOrder(_order.data.data.order)
            } catch (error) {
                console.log(error)
            }
        },
        onRefreshPaymentData: async() => {
            try {
                let order_id = match.params.id
                let finalArgs = {"AND":[{"field":"order_id","operator":"=","value":order_id}]}
                const _payment = await private_server.get(`/orderpayment/all?filter=${encodeURIComponent(JSON.stringify(finalArgs))}`)
                setPayments(_payment.data.data.order_payments)
            } catch (error) {
                console.log(error)
            }
        },
        onUpdateUsers: async() => {
            try {
                let user_id = match.params.id
                const _order = await private_server.get(`/order/pharma/me${user_id}`)
                console.log(user)
                setOrder(_order.data.data)
            } catch (error) {
                
            }
            
        },
        onEditPaymentItem: (_data) => {
            console.log(_data)
            setSelectedPayment(_data)
            actions.onUpdateModal('edit_payment',true)
        },
        onUploadEvidence: (_data) => {
            console.log(_data)
            setSelectedPayment(_data)
            actions.onUpdateModal('upload_evidence',true)
        },
        onDownloadEvidence: async(_data) => {
            try {
                updateAuthorizationHeader(private_server)
                let response = await private_server.get(`/orderpayment/${_data.id_order_payment}/file`, {params:{name:'receipt'}} )
                delete axios.defaults.headers.common["Authorization"]
                response = await axios.get(response.data.data.url, {responseType: 'blob'});
                FileSaver.saveAs(response.data, `evidence`);
            } catch (error) {
                
            }
        },
        onUpdateQuery:(data) => {
            setQuery(data)
        },
        onSelectedItem: () => {

        },
        onSelectedItem: async(data) => {
            console.log('Disparar busqueda')
            const _order = await private_server.get(`/user/by/${data._id}`)
            console.log(user)
            setSelectedUser(_order.data.data)
            actions.onUpdateModal({edit_user:true})
        },
        onChangeFilter: () => {

        },
        onUpdateUser: (data) => {
            setSelectedUser(data)
        },
        onUpdateModal: (_key, _value) => {
            let _modals = {...modals, [_key]:_value}
            setModals(_modals)
        }

    }

    const system = {loading, sending, error}
    const view_data = {users, order, query, total, profile_view, pharmaceuticals, payments, selected_payment}

    return {system, modals, view_data, actions}
}

export default useOrderDetailsView