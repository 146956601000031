import { Button, Grid, Icon, IconButton, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'


//pruebas
import nouser from '../../../../assets/nouser.png'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { onGetDateFormat, onGetFullname } from "../../../../shared/utility"
import { nodata } from "../../../../config"
import moment from "moment"
import StatusData from "../../../../components/Structure/DisplayData/StatusData"
import { catalogs } from "../../../../texts/esp/catalogs"
import { blueGrey } from "@material-ui/core/colors"
import VerticalData from "../../../../components/Structure/DisplayData/VerticalData"
import GenericStatus from "../../../../components/Structure/DisplayData/GenericStatus"

const OrderInfoCard = ({order, onUpdatePassword, onEdit, actions}) =>{

    const classes = useStyles()

    let pharma = nodata
    let compendium_key = nodata
    let external_id = nodata
    let exercise_year = nodata
    let order_applicant = nodata
    let order_event = nodata
    let units = nodata
    let contract_id = nodata
    let replacement_order_id = nodata
    let order_delivery_type = nodata
    let delivery_institution = nodata
    let clue = nodata
    let order_reception_date = nodata
    let order_expiration_date = nodata
    let amount = nodata
    let delivery_date = nodata
    let collection_entry_date = nodata
    let payment_date = nodata
    let status_id
    
    let status = {}
    if(order){
        pharma = order.pharma ? order.pharma : nodata
        compendium_key = order.compendium_key ? order.compendium_key : nodata
        external_id = order.external_id ? order.external_id : nodata
        exercise_year = order.exercise_year ? order.exercise_year : nodata
        order_applicant = order.order_applicant ? order.order_applicant : nodata
        external_id = order.external_id ? order.external_id : nodata
        order_event = order.order_event ? order.order_event : nodata
        units = order.units ? order.units : nodata
        contract_id = order.contract_id ? order.contract_id : nodata
        replacement_order_id = order.replacement_order_id ? order.replacement_order_id : nodata
        order_delivery_type = order.order_delivery_type ? order.order_delivery_type : nodata
        delivery_institution = order.delivery_institution ? order.delivery_institution : nodata
        clue = order.clue ? order.clue : nodata
        order_reception_date = onGetDateFormat(order.order_reception_date, 'DD MMM YYYY')
        order_expiration_date = onGetDateFormat(order.order_expiration_date, 'DD MMM YYYY')
        delivery_date = onGetDateFormat(order.delivery_date, 'DD MMM YYYY')
        collection_entry_date = onGetDateFormat(order.collection_entry_date, 'DD MMM YYYY')
        payment_date = onGetDateFormat(order.payment_date, 'DD MMM YYYY')
        status_id = order.user_status_id
        status = catalogs.user_statuses.find(el => el.value === order.status_id)
        //const temp_gender = catalogs.genders.find(el => el.value === order.gender_id)
        //if(temp_gender) usergender = temp_gender.label
    }//

    return(
        <div>
            
            <SimpleCard>
                <div className={classes.container2}>
                    <Typography variant='h6' style={{marginBottom:24}}>Detalles</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Empresa'
                            data={pharma}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Clave de compendio'
                            data={compendium_key}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='ID externo'
                            data={external_id}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Año de ejercicio'
                            data={exercise_year}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Solicitante'
                            data={order_applicant}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Evento'
                            data={order_event}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Unidades adjudicadas'
                            data={units}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Monto'
                            data={amount}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='P.O. (Contrato)'
                            data={contract_id}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='Orden de reposición'
                            data={replacement_order_id}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData 
                            label='CLUE'
                            data={clue}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'style={{marginBottom:16}}>Datos de entrega</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                    label='Tipo de entrega'
                                    data={order_delivery_type}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                    label='Instituto de entrega'
                                    data={delivery_institution}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                    label='Entidad de entrega'
                                    data={nodata}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'style={{marginBottom:16}}>Tiempos</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                    label='Fecha de recepción pedido'
                                    data={order_reception_date}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                    label='Fecha de vencimiento pedido'
                                    data={order_expiration_date}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                    label='Fecha de entrega'
                                    data={delivery_date}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                    label='Fecha de ingreso a cobro'
                                    data={collection_entry_date}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <VerticalData 
                                    label='Fecha de pago'
                                    data={payment_date}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={onEdit} >Editar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </SimpleCard>
            
        </div>
    )

}

const useStyles = makeStyles(theme => ({
    container1:{
        display:'flex',
        justifyContent:'center',
    },
    avatar_container:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        padding:'20px 16px',
    },
    button:{
        border:`1px solid ${theme.palette.primary.main}`,
        //boxShadow:'0px 0px 5px rgba(237, 28, 36, 0.33)',
        borderRadius:20,
        color:theme.palette.primary.dark,
        padding:'10px 20px',
        textTransform:'none',
        marginTop:20
    },
    name:{
        marginTop:20
    },
    container2:{
        padding:20,
        [theme.breakpoints.down('sm')]:{
            padding:8,
        }
    },
    topbar:{
        marginBottom:20
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
        //marginRight:16
    }
    
}))

export default OrderInfoCard

const TitleText = (props) => {
    return(
        <Typography variant='body2' style={{color:'#909090'}}>{props.children}</Typography>
    )
}