import React, { useState, useEffect } from 'react';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import logo from '../../../../assets/icons/pharmacy.png'
import { Grid, Icon, Typography } from '@material-ui/core';
import { nodata } from '../../../../config';
import { makeStyles } from '@material-ui/styles';
import { catalogs } from '../../../../texts/esp/catalogs';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell} from 'recharts';


const PieChartOrders = ({data}) => {

    const classes = useStyles()
    const [activeIndex, setActiveIndex] = useState(0)
    const [statuses, setStatuses] = useState([])
    const [selected, setSelected] = useState({})

    useEffect(() => {
        let _statuses = catalogs.orders_statuses.map(item => {
            return {
                name:item.label,
                value:item.number,
                color:item.color
            }
        })
        setSelected(_statuses[0])
        setStatuses(_statuses)
    }, [])

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    };

    const onMouseEnter = (item) => {
        console.log('Celda se mueve')
        console.log(item)
        setSelected(item)
    }

    let name = nodata
    let status_id 
    let email = nodata
    let mobile = nodata

    if(data){
        name = data.name ? data.name : nodata
        status_id = data.pharma_status_id ?  data.pharma_status_id : nodata
        email = data.email ?  data.email : nodata
        mobile = data.mobile ?  data.mobile : nodata
    }

    return (  
        <SimpleCard>
            <div className={classes.root}>
                <Typography className={classes.title}>Estatus de órdenes</Typography>
                <div style={{marginTop:8, marginBottom:8}}>
                    <Grid container>
                        {catalogs.orders_statuses.map(item => {
                            return(
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <Grid item><Icon style={{fontSize:14, marginRight:4, color: item.color}}>brightness_1</Icon></Grid>
                                        <Grid item><Typography variant='body2' color='textSecondary'>{item.label}</Typography></Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                <div style={{marginTop:40, position:'relative'}}>
                    <div className={classes.chart_data}>
                        <Typography align='center' style={{fontSize:36, color:'black', fontWeight:600,}}>{Math.round(selected.value*100).toString()}<sup>%</sup></Typography>
                        <Typography align='center' style={{fontSize:16, fontWeight:600, color:"#868686"}}>{selected.name}</Typography>
                    </div>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <PieChart width={250} height={250}>
                                <Pie
                                    data={statuses}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={90}
                                    outerRadius={120}
                                    dataKey="value"
                                    onMouseEnter={onPieEnter}
                                >
                                    {statuses.map((entry, index) => {
                                        return <Cell key={`cell-${index}`} fill={entry.color} onMouseEnter={() => onMouseEnter(entry)} />
                                })}
                                </Pie>
                            </PieChart>
                        </Grid>
                    </Grid>
                        
                </div>
            </div>
        </SimpleCard>

    );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'8px 0px'
    },
    chart_data:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    title:{
        fontWeight:700,
        fontSize:18,
        color:"#000",
    }
}))
 
export default PieChartOrders;