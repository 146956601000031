import { amber, blue, cyan, green, indigo, orange, purple, red, teal, pink, lime, grey } from "@material-ui/core/colors";

export const catalogs = {
    intercesion_days:[],
    intercesion_hours :[],
    user_statuses:[
        {value:1, label:'Activo', color:green[700]},
        {value:2, label:'Inactivo', color:red[700]},
    ],
    orders_statuses:[
        {value:1, label:'En proceso', color:"#A149C0", number:0.42},
        {value:2, label:'Entregado', color:green[700], number:0.23},
        {value:3, label:'Vencido', color:red[700], number:0.3},
        {value:4, label:'Cancelado', color:grey[700], number:0.05},
    ],
    applicants:[
        {value:1, label:'UNOPS', color:green[700]},
        {value:2, label:'INSABI-OM', color:red[700]},
    ],
    orders_years:[
        {value:'2021', label:'2021', color:green[700]},
        {value:'2022', label:'2022', color:red[700]},
    ],
    orders_events:[
        {value:1, label:'Patentes', color:"#A149C0", },
        {value:2, label:'Fuente única', color:green[700], },
        {value:3, label:'Oncológicos', color:red[700], },
        {value:4, label:'Priorizados', color:grey[700], },
    ],
    orders_invoiced:[
        {value:1, label:'Si', color:green[700]},
        {value:0, label:'No', color:red[700]},
    ],
    orders_delivery_types:[
        {value:1, label:'Directo', color:"#A149C0", },
        {value:2, label:'Operador logistico', color:green[700], },
        {value:3, label:'Domiciliado', color:red[700], },
    ],
    genders:[
        {value:1, label:'Masculino'},
        {value:2, label:'Femenino'},
        {value:3, label:'Otro'}
    ],
    specialities:[
        {id:1, label:'Dermatología'},
        {id:2, label:'Medicina General'},
        {id:3, label:'Nutrición'},
        {id:4, label:'Psicología'},
    ],
    currency:[
        {value:1, label:'Dolar (USD)', code:'USD', text_id:301},
        {value:2, label:'Peso (MXN)', code:'MXN', text_id:302},
    ],
}