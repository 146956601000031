import React, { useState, useEffect } from 'react';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import logo from '../../../../assets/icons/bill.png'
import { Grid, Icon, Typography } from '@material-ui/core';
import { nodata } from '../../../../config';
import { makeStyles } from '@material-ui/styles';
import GenericStatus from '../../../../components/Structure/DisplayData/GenericStatus';
import { catalogs } from '../../../../texts/esp/catalogs';
import { onGetCurrencyValue } from '../../../../shared/utility';
import BalanceProgressBar from '../../../../components/Structure/DisplayData/BalanceProgressBar';
const BillCard = ({data}) => {

    const classes = useStyles()

    let compendium_key = nodata
    let status_id 
    let amount = nodata
    let paid_amount = nodata
    let email = nodata
    let mobile = nodata

    if(data){
        compendium_key = data.compendium_key ? data.compendium_key : nodata
        status_id = data.order_status_id ?  data.order_status_id : nodata
        email = data.email ?  data.email : nodata
        mobile = data.mobile ?  data.mobile : nodata
        amount = onGetCurrencyValue(data.amount)
        //paid_amount = onGetCurrencyValue(data.paid_amount)
        paid_amount = onGetCurrencyValue(data.paid_amount)

    }

    return (  
        <SimpleCard>
            <div className={classes.root}>
                <Grid container direction='column' alignItems='center' spacing={1}>
                    <Grid item>
                        <img src={logo} width={100}/>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title}>{compendium_key}</Typography>
                    </Grid>
                    <Grid item>
                        <div style={{marginBottom:16}}>
                            <GenericStatus value={status_id} catalogs={catalogs.orders_statuses} />
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{display:'flex', alignItems:'baseline'}}>
                            <Typography color='textSecondary'>Monto</Typography>
                            <Typography variant='subtitle1' style={{marginLeft:8}}>{amount}</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{display:'flex', alignItems:'baseline'}}>
                            <Typography color='textSecondary'>Aportación</Typography>
                            <Typography variant='subtitle1' style={{marginLeft:8}}>{paid_amount}</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{width:200}}>
                         <BalanceProgressBar size='large' amount={data ? data.units : 0.00} paid={data ? data.units_paid : 0.00} />
                        </div>
                        
                    </Grid>
                </Grid>
            </div>
        </SimpleCard>

    );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'30px 0px'
    },
    title:{
        fontWeight:700,
        fontSize:18,
        color:"#425C7E",
        width:200,
        textAlign:'center'
    }
}))
 
export default BillCard;