import { Grid, makeStyles } from "@material-ui/core"
import { connect } from 'react-redux';
import system_translations from "../../../texts/system_translations";
import UserInfo from "./components/OrderInfoCard"
import useOrderDetailsView from './useOrderDetailsView'
import PasswordModal from './modals/PasswordModal/PasswordModal';
import EditUserModal from './modals/EditUserModal/EditUserModal';
import Page from "../../../components/Structure/Layouts/Page";
import ProfilePictureModal from "./modals/ProfilePictureModal/ProfilePictureModal";
import BillCard from "./components/BillCard";
import PieChartOrders from "./components/PieChartOrders";
import OrderInfoCard from "./components/OrderInfoCard";
import PaymentsCard from "./components/PaymentsCard";
import AddPaymentModal from "./modals/AddPaymentModal/AddPaymentModal";
import EditPaymentModal from "./modals/EditPaymentModal/EditPaymentModal";
import DocumentModal from "./modals/DocumentModal";


const OrderDetailsView = ({language, history, user, match}) => {

    const classes = useStyles()
    const content = system_translations[language].views.user

    const {view_data, actions, modals, system} = useOrderDetailsView({history, user, match})




    return(
        <Page title={"Detalle de orden"} setReturn loading={system.loading} >  
            <AddPaymentModal order_id={match.params.id}  open={modals.add_payment} actions={actions} onClose={() => actions.onUpdateModal('add_payment', false)}/>
            <EditPaymentModal origin={view_data.selected_payment} order_id={match.params.id} actions={actions} open={modals.edit_payment} onClose={() => actions.onUpdateModal('edit_payment', false)}/>
            <EditUserModal origin={view_data.order} open={modals.edit_user} history={history} actions={actions} 
            view_data={view_data}
            onClose={() => actions.onUpdateModal('edit_user', false)} />
            <DocumentModal origin={view_data.selected_payment} actions={actions} open={modals.upload_evidence} onClose={() => actions.onUpdateModal('upload_evidence', false)}/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <BillCard data={view_data.order}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <OrderInfoCard order={view_data.order} onEdit={() => actions.onUpdateModal('edit_user', true)}/>
                        </Grid>
                        {/*<Grid item xs={12}>
                            <PaymentsCard data={view_data.payments} onEdit={() => actions.onUpdateModal('edit_user', true)}
                            onAddRegister={() => actions.onUpdateModal('add_payment', true)}
                            onSelectedItem={actions.onEditPaymentItem}
                            onUploadDocument={actions.onUploadEvidence}
                            onDownloadDocument={actions.onDownloadEvidence}/>
    </Grid>*/}
                    </Grid>
                </Grid>

            </Grid>

        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language:state.language, 
    }
}

export default connect(mapStateToProps)(OrderDetailsView)

const useStyles = makeStyles(theme => ({
    edit_button:{
        background:theme.palette.primary.main,
        borderRadius:20,
        boxShadow:'0px 2px 7px rgba(0, 0, 0, 0.31)',
        textTransform:'none',
        color:'white',
        padding:'8px 28px'
    },
    delete_button:{
        background:'white',
        color:theme.palette.primary.main,
        boxShadow:'0px 5px 7px rgba(0, 0, 0, 0.12)'
    }
    
}))