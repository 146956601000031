import { makeStyles } from '@material-ui/core';
import React from 'react';


const BalanceProgressBar = ({amount, paid, size}) => {


    const classes = useStyles()

    let _amount = amount ? parseFloat(amount) : 0.00
    let _paid = paid ? parseFloat(paid) : 0.00
 
    const result = _paid/_amount
    let color = "#1C63BF"
    if(result > 0.25) color = "#1C8ABA"
    if(result > 0.75) color = "#1291DA"

    return ( 
        <div>
            <div style={{color:color, fontWeight:700, fontSize:size === 'large' ? 24 :16, textAlign:'center'}}>{`${Math.ceil(result*100)}%`}</div>
            <div className={classes.bar} style={{height: size === 'large' ? 10 : 6}}>
                <div className={classes.indicator} style={{width:`${result*100}%`, background:color,height: size === 'large' ? 10 : 6}}/>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    bar:{
        background:"#A7B7CB",
        height:6,
        borderRadius:12,
        width:'100%',
        position:'relative'
    },
    indicator:{
        position:'absolute',
        top:0, left:0,
        height:6,
        borderRadius:12,
        background:'blue',
        zIndex:2

    }
}))
 
export default BalanceProgressBar;