import { alpha, Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SimpleCard from '../../../components/Structure/Cards/SimpleCard';
import Page from '../../../components/Structure/Layouts/Page';
import { onGetCommasValue, onGetCurrencyValue, onGetFormatNumber } from '../../../shared/utility';
import { actionTypes } from '../../../store/actions';
import CircularProgressBar from './components/CircularProgressBar';
import FormatsSection from './components/FormatsSection';
import SimpleTable from './components/SimpleTable';
import AddUserModal from './modals/AddUserModal/AddUserModal';
import UploadDocumentModal from './modals/AddUserModal/UploadDocumentModal/UploadDocumentModal';
import DeleteOrderModal from './modals/DeleteOrderModal';
import EditOrderModal from './modals/EditOrderModal/EditOrderModal';
import useOrdersView from './useOrdersView';


const OrdersView = ({user, history}) => {

    //const content = translations_esp
    const classes = useStyles()
    const {system, view_data, actions, modals } = useOrdersView({user})


    return ( 
        <div style={{minHeight:'100vh'}}>
            <Page title='Órdenes'>
                <DeleteOrderModal origin={view_data.order} open={modals.delete_order} 
                    onClose={() => actions.onUpdateModalStatus('delete_order', false)}  actions={actions}/>
                <EditOrderModal origin={view_data.order} open={modals.edit_parameter} parameter={view_data.parameter}
                onClose={() => actions.onUpdateModalStatus('edit_parameter', false)}  actions={actions}/>
                <UploadDocumentModal open={modals.upload_document}  actions={actions}
                 onClose={() => actions.onUpdateModalStatus('upload_document', false)}/>
                <AddUserModal master={user} open={modals.add_register} onSubmit={actions.onSubmit} actions={actions} pharmas={view_data.pharmaceuticals}
                onClose={() => actions.onUpdateModalStatus('add_register', false)}/>
                <div>
                    <FormatsSection onDownload={actions.onDownloadInformation} onUpload={actions.onUploadInformation}/>
                </div>
                <div>
                    <Grid container spacing={2}>
                        {view_data.order_overview.map(item => {
                            if(item.hidden) return null
                            return(
                                <Grid item key={`key-${item.id}`}>
                                    <SimpleCard padding='small'>
                                        <div className={classes.card}>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <div className={classes.icon_container} style={{background:alpha(item.color, 0.5)}}>
                                                        <img src={item.icon} className={classes.icon}/>
                                                    </div>
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant='body2' style={{color:'#A9A9A9'}}>{item.label}</Typography>
                                                    <Typography style={{fontSize:24, marginTop:2}}>{ onGetCommasValue(item.total_units ? item.total_units : null) }<sup className={classes.total}>{`/${view_data.progress.total}`}</sup></Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        
                                    </SimpleCard>
                                </Grid>
                            )
                        })}
                        <Grid item xs></Grid>
                        <Grid item>
                            <CircularProgressBar progress={view_data.progress}/>
                        </Grid>
                    </Grid>
                    
                </div>
                
                <div>
                    <SimpleTable master={user}
                        loading={system.loading || system.sending}
                        data={view_data.data} 
                        filter={view_data.filter}
                        tableFilter={view_data.tableFilter}
                        total={view_data.total} 
                        loading={system.loading}
                        onSelectedItem={actions.onItemSelected}
                        onDeleteItem={actions.onDeleteSelected}
                        onAddRegister={() => actions.onUpdateModalStatus('add_register', true)}
                        onUpdateTableFilter={actions.onUpdateTableFilter}
                        onChangeFilter={actions.onChangeFilter} 
                        onChangeSearch={actions.onChangeSearch}/>
                </div>
            </Page>
        </div>  
     );
}

const useStyles = makeStyles(theme => ({
    icon:{
        width:'100%',
        height:'100%',
        objectFit:'contain'
    },
    icon_container:{
        width:54, height:54,
        padding:12,
        boxSizing:'border-box',
        borderRadius:'50%'
    },
    card:{
        padding:'8px 16px'
    },
    total:{
        color:grey[500],
        fontSize:16,
        //paddingTop:16
    }
}))

const mapStateToProps = (state) => {
    return {
        user:state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(OrdersView);


