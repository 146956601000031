import React, { useState, useEffect } from 'react';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import logo from '../../../../assets/icons/pharmacy.png'
import { Grid, Icon, Typography } from '@material-ui/core';
import { nodata } from '../../../../config';
import { makeStyles } from '@material-ui/styles';
import GenericStatus from '../../../../components/Structure/DisplayData/GenericStatus';
import { catalogs } from '../../../../texts/esp/catalogs';
const PharmaCard = ({data}) => {

    const classes = useStyles()

    let name = nodata
    let status_id 
    let email = nodata
    let mobile = nodata

    if(data){
        name = data.name ? data.name : nodata
        status_id = data.pharma_status_id ?  data.pharma_status_id : nodata
        email = data.email ?  data.email : nodata
        mobile = data.mobile ?  data.mobile : nodata
    }

    return (  
        <SimpleCard>
            <div className={classes.root}>
                <Grid container direction='column' alignItems='center' spacing={1}>
                    <Grid item>
                        <img src={logo} width={140}/>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title}>{name}</Typography>
                    </Grid>
                    <Grid item>
                        <div style={{marginBottom:16}}>
                            <GenericStatus value={status_id} catalogs={catalogs.user_statuses} />
                        </div>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item><Icon style={{color:"#1C63BF"}}>mail</Icon></Grid>
                            <Grid item><Typography variant='subtitle1'>{email}</Typography></Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item><Icon style={{color:"#1C63BF"}}>phone</Icon></Grid>
                            <Grid item><Typography variant='subtitle1'>{mobile}</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </SimpleCard>

    );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'30px 0px'
    },
    title:{
        fontWeight:700,
        fontSize:18,
        color:"#425C7E",
        width:200,
        textAlign:'center'
    }
}))
 
export default PharmaCard;