import { alpha, Button, Grid, Icon, makeStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import { useRef } from 'react';


const FormatsSection = ({onDownload, onUpload}) => {

    const classes = useStyles()
    const inputRef = useRef()

    const onAddFile = () => {
        console.log(inputRef)
        inputRef.current.click()
    }

    const onFileChange = (e) => {
        console.log('Cambio de archivo 2')
        console.log(e.target.result)
    }


    useEffect(() => {
        inputRef.current.onchange = onFileChange
    }, [])

    return ( 
        <div className={classes.root}>
            <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item>
                    <Button className={classes.button} onClick={onDownload} ><Icon>download</Icon>Descarga de formato</Button>
                </Grid>
                <Grid item>
                    <Button className={classes.button} onClick={onUpload} ><Icon>upload</Icon>Subir información</Button>
                    <input ref={inputRef} type="file"   hidden  onChange={onFileChange} ></input>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        marginBottom:16
    },
    button:{
        border:`2px dotted ${theme.palette.primary.main}`,
        padding:'12px 24px',
        borderRadius:12,
        background:alpha(theme.palette.primary.main,0.2),
        fontFamily:theme.typography.fontFamily,
        color:blueGrey[900],
        fontWeight:600,
        '&:hover':{
            background:alpha(theme.palette.primary.main,0.3),
        }
        
    }
}))
 
export default FormatsSection;