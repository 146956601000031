import React, {useState} from 'react'
import { withStyles, Grid, IconButton, Icon, makeStyles, fade, Typography } from '@material-ui/core'
import Dropzone from 'react-dropzone'
import { blue, green, grey, red, teal } from '@material-ui/core/colors'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    root:{},
    container:{
        background:grey[100],
        borderRadius:12,
        padding:32,
        transition: theme.transitions.create("background", {
            easing: theme.transitions.easing.sharp, 
            duration: 500,
        })
    },
    containerIn:{
        background:teal[100],
        cursor:'pointer'
    },
    button:{
        textTransform:'none',
        borderRadius:8
    },
    input:{
        display:'none',
        background:'grey'

    }
}))

const InputFile = props => {

    const classes = useStyles()
    const {id, file, onDocumentAttached, label, onChange, onDelete} = props
    const [enter, setEnter] = useState(false)

    const onDrop = (acceptedFiles) => {
        onChange(acceptedFiles[0])
        setEnter(false)
    }
    
    const onDragEnter = () => setEnter(true)
    const onDragLeave = () => setEnter(false)
    
    return(
        <div>
            <Grid container spacing={1}>
                {label ? (
                    <Grid item xs={12}>
                        <Typography variant='subtitle2'>{label}</Typography>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Dropzone onDragLeave={onDragLeave} onDragEnter={onDragEnter} onDrop={onDrop} maxSize={26214400} >
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className={cx({
                                [classes.container]: true,
                                [classes.containerIn]:enter
                            })}>
                                <input {...getInputProps()} />
                                <FileView file={file} onDocumentAttached={onDocumentAttached}/>
                            </div>
                        )}
                    </Dropzone>
                    <Typography variant='body2'>Tamaño máximo del archivo: 25MB</Typography>
                </Grid>
                {file ? <Grid item xs={12}>
                    <Grid container wrap='nowrap' alignItems='center'>
                        <Grid item xs>
                            <Typography variant='body2'>
                                Si desea modificar el archivo de click o arrastre uno nuevo en el recuadro
                            </Typography>
                        </Grid>
                        <Grid item><IconButton size='small' onClick={onDelete}><Icon fontSize='small'>delete</Icon></IconButton></Grid>
                    </Grid>
                </Grid> : null}
            </Grid>
            
        </div>
    )
}


export default InputFile

const useFileStyles = makeStyles(theme => ({
    docContainer:{
        position:'relative',
        background: fade(blue[300],0.85),
        width:40,
        height:48,
        borderRadius:8,
        color:'white',
        fontWeight:500,
        fontSize:14
    },
    docContainerWrong:{
        background: grey[300],
        color:grey[700],
    },
    docText:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    },
    goodIcon:{
        paddingTop:4,
        paddingLeft:4,
        color:green[700]
    },
    wrongIcon:{
        paddingTop:4,
        paddingLeft:4,
        color:red[700]
    },
    iconUpload:{
        color:theme.palette.primary.main,

    }
}))


const FileView = props => {

    const classes = useFileStyles()
    const {file} = props

    let filename = 'N/D'
    let size = ''
    let type = 'N/D'

    let iconContent = (
        <Icon className={classes.wrongIcon}>error</Icon>
    )

    let contentView = (
        <Grid container direction='column' alignItems='center'>
            <Grid item><Icon color='primary' className={classes.iconUpload} fontSize='large'>cloud_upload</Icon></Grid>
            <Grid item>
                <Typography variant='body2'>De click o arrastre aquí o</Typography>
            </Grid>
            <Grid item>
                <Typography color='primary' variant='body2'>Encuentre un archivo</Typography>
            </Grid>
        </Grid>
    )

    if(file){
        filename = file.name
        size = `${Math.ceil(file.size / 1024).toString()} kB`
        type = file.name.split('.').slice(-1)[0] 
        iconContent = (
            <Icon className={classes.goodIcon}>verified_user</Icon>
        )

        contentView = (
            <Grid container spacing={1} wrap='nowrap'>
                <Grid item>
                    <div className={cx({
                        [classes.docContainer]:true,
                        [classes.docContainerWrong]: !file
                    })}>
                        <div className={classes.docText}>{type}</div>
                    </div>
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container alignItems='center'>
                                <Grid item>
                                    <Typography variant='body2'>{filename}</Typography>
                                </Grid>
                                <Grid item>
                                    {iconContent}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2'>{size}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }else{

    }

    return(
        <div>{contentView}</div>
    )
}

