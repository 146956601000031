import { useState, useEffect } from "react"
import { updateAuthorizationHeader } from '../../../../../shared/utility'
import { private_server, public_server } from '../../../../../config'
import { generic_errors } from '../../../../../texts/esp/genericErrors'
import queryString from 'query-string'

const usePharmaUsersSecion = ({pharma}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [selectedItem, setSelectedItem] = useState(null)
    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false
    })
    const [selected_user, setSelectedUser] = useState(null)


    useEffect(() => {
        if(pharma) actions.onInitModule()
    }, [filter, tableFilter, pharma])

    const onGetParams = () => {
        const {limit, offset} = tableFilter

        let andFiltersArgs = [
            {"field":"user_type_id","operator":"=","value":3},
            {"field":"pharma_id","operator":"=","value":pharma.id_pharma},
        ]

        let finalArgs = {
            "AND":andFiltersArgs
        }

        console.log(finalArgs)

        const param2send = {limit:limit, offset:limit*offset, order_by:'id_user', order:1}
        const params = `?${queryString.stringify(param2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`


        return params
    }
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/user/all${_params}`)
                const temp = users_request.data.data
                setTotal(temp.count)
                setData(temp.users)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onUpdateUsers: async() => {
            setSending(true)
            try {
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/user/all${_params}`)
                const temp = users_request.data.data
                setTotal(temp.count)
                setData(temp.users)
            } catch (error) {
                console.log(error)
            }
            setSending(false)
        },
        onEditUser: (_data) => {
            setSelectedUser(_data)
            actions.onUpdateModalStatus('edit_register', true)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onChangeFilter: (data) => setFilter(data)
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, filter, selectedItem, selected_user}

    return {system, actions, view_data, modals}
}

export default usePharmaUsersSecion