import { useState, useEffect } from "react"
import { onGetCatalogs, s2ab, updateAuthorizationHeader } from "../../../shared/utility"
import { excel_column, excel_template, ORDER_MATRIX, private_server, public_server, USER_TYPES } from "../../../config"
import queryString from 'query-string'

import icon_check from '../../../assets/icons/check.svg'
import icon_flag from '../../../assets/icons/flag.svg'
import icon_clock from '../../../assets/icons/clock.svg'

import { Workbook } from 'exceljs';
import FileSaver from 'file-saver'
import moment from "moment"

import tempFile from '../../../assets/files/template.xlsx'
import { catalogs } from "../../../texts/esp/catalogs"

const useOrdersView = ({user}) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:50,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [selectedItem, setSelectedItem] = useState(null)
    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false,
        upload_document:false,
        edit_parameter:false,
        delete_order:false,
    })
    const [order_overview, setOrderOverview] = useState([
        {id:2, icon:icon_check, color:'#DBF4D6', label:'Órdenes entregadas', value:3548},
        {id:3, icon:icon_flag, color:'#F9CECE', label:'Órdenes vencidas', value:1219},
        {id:1, icon:icon_clock, color:'#D1CEF9', label:'Órdenes en proceso', value:2564},
        {id:4, icon:icon_clock, color:'#D1CEF9', label:'Órdenes canceladas', value:2564, hidden:true},
    ])
    const [pharmaceuticals, setPharmaceuticals] = useState([])
    const [progress, setProgress] = useState({total:1, completed:0})

    const [order, setOrder] = useState(null)
    const [parameter, setParameter] = useState(null)

    useEffect(()=>{window.scrollTo(0,0)},[])

    useEffect(() => {
        console.log(user)
        if(user) actions.onInitModule()
    }, [filter, tableFilter, user])

    const onGetParams = () => {
        const {limit, offset} = tableFilter
        const {query} = filter

        let andFiltersArgs = [
            //{"field":"name","operator":"LIKE","":"%%"},
        ]

        if(query) andFiltersArgs.push({"field":"contract_id","operator":"LIKE", "value":`%${query}%`})

        let finalArgs = {
            "AND":andFiltersArgs
        }

        console.log(JSON.stringify(finalArgs))

        const param2send = {limit:limit, offset:limit*offset, order_by:'id_order', order:0}
        let params = `?${queryString.stringify(param2send)}`
        if(andFiltersArgs.length) params = `${params}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`

        return params
    }

    const onGetPrincipalData = async() => {
        try {
            const _params = onGetParams()

            let isPharma = user.user_type_id === USER_TYPES.PHARMA_ADMIN || user.user_type_id === USER_TYPES.PHARMA_EMPLOYEE;
            let orders_route = `/order/all${_params}`
            let analytics_route = isPharma ? 'analytic/pharma/me/countordersbystatus' : '/analytic/countordersbystatus/all'
            if(isPharma){
                orders_route = `/order/pharma/me/all${_params}`
            }
            const users_request = await private_server.get(orders_route)
            const temp = users_request.data.data
            setTotal(temp.count)
            setData(temp.orders)

            const analytics_response = await private_server.get(analytics_route)
            console.log(analytics_response.data.data.orders)
            const analytics = analytics_response.data.data.orders

            let _order_overview = [...order_overview]
            let total_units = 0;
            let total_completed = 0;
            for(let i=0; i<order_overview.length; i++){
                let current = order_overview[i]
                let item = analytics.find(el => el.order_status_id === current.id)
                if(item){
                    current = {...current, ...item}
                    total_units += parseInt(current.total_units)
                    if(current.id === 2) total_completed = parseInt(current.total_units)
                }
                _order_overview[i] = {...current}
            }
            setOrderOverview(_order_overview)
            setProgress({total:total_units, completed: total_completed})
        } catch (error) {
            
        }
    }
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                
                updateAuthorizationHeader(private_server)

                await onGetPrincipalData()
                let isPharma = user.user_type_id === USER_TYPES.PHARMA_ADMIN || user.user_type_id === USER_TYPES.PHARMA_EMPLOYEE;

                let pharmas = []
                if(!isPharma){
                    const pharma_request = await private_server.get(`/pharma/all`)
                    pharmas = onGetCatalogs(pharma_request.data.data.pharmas,'id_pharma','name')
                }
                setPharmaceuticals(pharmas)

            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onUpdateUsers: async() => {
            setSending(true)
            try {
                await onGetPrincipalData()

            } catch (error) {
                console.log(error)
            }
            setSending(false)
        },
        onItemSelected: async(data, key) => {
            
            setOrder(data)
            setParameter(key)
            actions.onUpdateModalStatus('edit_parameter', true)
        },
        onDeleteSelected: async(data) => {
            console.log('ELiminar')
            setOrder(data)
            actions.onUpdateModalStatus('delete_order', true)
        },
        onChangeSearch: (data) => {
            console.log(data)
            let _filter = {...filter}
            _filter.query = data
            setFilter(_filter)
        },
        onDownloadInformation: async() => {
            console.log('On Download')
            console.log(tempFile)
            try {
                //Generate data to excel

                let template_config = [...excel_template]
                for(let i = 0; i<excel_template.length; i++){
                    const current = excel_template[i]
                    if(current.cat){
                        const cat = catalogs[current.cat]
                        let options = `"`
                        for(let j = 0; j<cat.length; j++){
                            if(j < cat.length -1 ) {
                                options=`${options}${cat[j].label},`
                            }else{
                                options=`${options}${cat[j].label}`
                            }
                        }
                        options=`${options}"`
                        template_config[i].options = options
                    }
                }

                let generated_data = []

                // Iterate orders
                for(let i=0; i<data.length; i++){
                    const current = data[i]
                    let temp = []
                    for(let j=0; j<template_config.length; j++){
                        const _key = template_config[j].key
                        let value = current[_key]
                        if(_key.includes('date')){
                            const temp = moment(value)
                            if(temp.isValid()){
                                let  excelvalue = Math.floor((temp.unix()/86400)+(25567+1))
                                console.log(excelvalue)
                                value = new Date(temp.year(), temp.month(), temp.date())  //excelvalue
                                //value = temp.isValid() ? temp.format('YYYY/MM/DD') : ''
                            }
                            
                            
                        }
                        temp.push(value !== null && value !== undefined ? value : '')
                    }
                    generated_data.push(temp)
                }

                //console.log(template_config)
                let workbook = new Workbook();
                let worksheet = workbook.addWorksheet('Ordenes');
                
                //Add header
                const header = []
                for(let i = 0; i<template_config.length; i++) header.push(template_config[i].name)

                worksheet.addRow(header);
                worksheet.columns.forEach(col => {
                    col.width = 30;
                });

                //Add data
                for(let i = 0; i<generated_data.length; i++){
                    worksheet.addRow(generated_data[i]);
                }

                const maxColum = generated_data.length

                //Add data validation
                for(let i = 0; i<template_config.length; i++){
                    const current = template_config[i]
                    if(current.cat){
                        for(let j = 1; j<maxColum+21; j++){
                            worksheet.getCell(`${current.col}${j}`).dataValidation = {
                                type:'list',
                                allowBlank:true,
                                operator: 'between',
                                formulae:[current.options],
                                showErrorMessage: true,
                            }
                        }
                    }
                    //if(current.key.includes('date')){
                    //    const column = excel_column(i)
                    //    workbook.getCell(`${column}${j}`).
                    //}
                }

                const datawork = await workbook.xlsx.writeBuffer()
                let blob = new Blob([datawork], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, 'example.xlsx');
                
            } catch (error) {
                console.log(error)
            }
        },
        onUploadInformation: async() => {
            console.log('On Upload')
            try {
                actions.onUpdateModalStatus('upload_document', true)

            } catch (error) {
                
            }
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onChangeFilter: (data) => setFilter(data)
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, filter, selectedItem, order_overview, pharmaceuticals, progress, order, parameter}

    return {system, actions, view_data, modals}
}

export default useOrdersView

/*

[
                    'ID',
                    'CLAVE DE COMPENDIO',
                    'AÑO DE EJERCICIO',
                    'SOLICITANTE',
                    'EVENTO',
                    'PO (CONTRATO)',
                    'ORDEN DE REPOSICIÓN',
                    'TIPO DE ENTREGA',
                    'INSTITUTO DE ENTREGA',
                    'ENTIDAD DE ENTREGA',
                    'CLUE',
                    'FECHA DE RECEPCIÓN',
                    'FECHA DE VENCIMIENTO',
                    'ESTATUS DE PEDIDO',
                    'FACTURADO',
                    'CANTIDAD',
                    'MONTO',
                    'CANTIDAD ENTREGADA',
                    'MONTO PAGADO',
                    'FECHA DE ENTREGA',
                    'FECHA DE INGRESO A COBRO',
                    'FECHA DE PAGO'
                ]

                */


