import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { onGetErrorMessage, onGetFileExtension, updateAuthorizationHeader } from '../../../../shared/utility';
//import { request_timereport, request_upload_document } from '../requests';
import SimpleModal from '../../../../components/Modals/SimpleModal';
import InputFile from '../../../../components/Forms/InputFile';
import ActionModalBar from '../../../../components/Actions/ActionModalBar';
import axios from 'axios'
import { private_server } from '../../../../config';
const DocumentModal = ({id, open, onClose, actions, origin}) => {

    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const onSubmit = async() => {try {
        if(!file){
            setError('Debe adjuntar un documento')
            return
        }
        setLoading(true)
        const fileext = onGetFileExtension(file.name)
        const file2send = {name:'receipt', ext:fileext}
        updateAuthorizationHeader(private_server)
        let response = await private_server.post(`/orderpayment/${origin.id_order_payment}/file`, file2send )
        const options = {headers: {'Content-Type': file.type}}
        delete axios.defaults.headers.common["Authorization"]
        await axios.put(response.data.data.url, file, options);
        updateAuthorizationHeader(private_server)
        actions.onRefreshPaymentData()
        actions.onUpdateModal('upload_evidence',false)
        onClose()

    } catch (error) {
        console.log(error)
        setError(onGetErrorMessage(error))   
    } setLoading(false)}

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6' color='primary'>Subir documento</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputFile id='file' file={file} label='Adjuntar evidencia' 
                        onChange={(file) => setFile(file)} onDelete={() => setFile(null)}/>
                </Grid>
                <Grid item xs={12}>
                    <ActionModalBar btnLabel='Subir' loading={loading} error={error} onSubmit={onSubmit}/>
                </Grid>
            </Grid>
        </SimpleModal>
     );
}
 
export default DocumentModal;