import general_es from './esp/general-translations'
import front_end_errors_es from './esp/frontend-erros'
import sigin_layout_es from './esp/signin-translations'
import sigup_layout_es from './esp/signup-translations'



const system_translations = {
    es:{
        errors: front_end_errors_es,
        general:general_es,
        layouts:{
            signin: sigin_layout_es,
            signup: sigup_layout_es
        },
        views:{

        },
        modals:{

        },
    },
    eng:{
        
    }
}

export default system_translations