import React, { useState, useEffect } from 'react';
import system_translations from '../../texts/system_translations';
import { alpha, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import useSignInLayout from './useSignInLayout';
import InputForm from '../../components/Forms/InputForm';
import logo from '../../assets/logo.png'
import RoundedButton from '../../components/Actions/RoundedButton';
import { Redirect } from 'react-router';
const SignInLayout = ({user, history, language, onUpdateAuthStatus, onUpdateUserData}) => {

    const classes = useStyles()
    const content = system_translations[language].layouts.signin
    const {system, form, actions} = useSignInLayout({language, content, onUpdateAuthStatus, onUpdateUserData})

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    let contentRedirect = null
    if(user) contentRedirect = <Redirect to='/orders'/>

    return ( 

        <div className={classes.root}>
            {contentRedirect}
                <div className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <div style={{position:'relative'}}>
                                <div className={classes.overlay}/>
                                <div className={classes.imageContainer}>
                                    <img src={content.banner} className={classes.image}/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={classes.left_container}>
                                <div className={classes.content_data}>
                                    <div className={classes.logo_container}>
                                        <img src={logo} width="100%"/>
                                    </div>
                                    <Typography variant='h4' align='center'>
                                        Bienvenido
                                    </Typography>
                                    <div style={{width:310, margin:'auto', marginTop:16}}>
                                        <Typography variant='subtitle1' align='center' color='textSecondary' >
                                            Por favor ingresa tus credenciales para iniciar sesión.
                                        </Typography>
                                    </div>
                                    

                                    <div className={classes.form}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <InputForm data={form.username} onChange={actions.onChangeForm}
                                                onKeyPress={actions.onKeyPress}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputForm data={form.password} onChange={actions.onChangeForm}
                                                onKeyPress={actions.onKeyPress}/>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    
                                    <Grid container justifyContent='flex-end'>
                                        <Grid item>
                                            <div className={classes.link}>{content.forgotten_password}</div>
                                        </Grid>
                                    </Grid>
                                    <div style={{marginTop:40, marginBottom:20}}>
                                        <RoundedButton fullWidth loading={system.sending} onClick={actions.onSubmit}>
                                            Acceder
                                        </RoundedButton>
                                    </div>
                                    {system.error ? (
                                        <Typography align='center' color='error' variant='subtitle2'>{system.error}</Typography>
                                    ) : null} 
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        fontFamily:theme.typography.fontFamily,
        position:'relative'
    },
    logo_container:{
        width:150,
        height:150,
        margin:'auto'
    },
    imageContainer:{
        width:'100%',
        position:'absolute',
        top:0, left:0,
        height:'100vh',
        //background:'red'
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    overlay:{
        width:'100%',
        position:'absolute',
        top:0, left:0,
        height:'100vh',
        zIndex:3,
        background: alpha('#1291DA',0.39),
    },
    container:{
        //width:1400,
        margin:'auto',
        height:'100vh',
        //background:'red',
        boxSizing:'border-box',
        [theme.breakpoints.only('lg')]:{
            //width:1200,
        },
        [theme.breakpoints.down('md')]:{
            width:'100%',
            //padding:'0px 18px'
            //padding:'12px 0px',
            //height:300
        },
    },
    left_container:{
        position:'relative',
        padding:'24px 60px',
        width:600,
        boxSizing:'border-box',
        margin:'auto',
        [theme.breakpoints.down('md')]:{
            width:'100%',
            //padding:'0px 18px'
            //padding:'12px 0px',
            //height:300
        },
    },
    content_data:{
        marginTop:80,
    },
    link:{
        color:theme.palette.primary.main,
        fontWeight:600,
        textDecoration:`underline ${theme.palette.primary.main}`,
        cursor:'pointer'

    },
    button:{
        fontSize:20,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
    },
    form:{
        margin:'40px 0px'
    }
}))

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateToken: (token) => dispatch({type:actionTypes.SYS_UPDATE_TOKEN, token}),
        onUpdateSessionStatus: (session_status) => dispatch({type:actionTypes.SYS_UPDATE_SESSION_STATUS, session_status}),
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(SignInLayout);