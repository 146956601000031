import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const VerticalData = ({label, data}) => {
    return ( 
        <div>
            <Typography variant='body2' color='textSecondary' >{label}</Typography>
            <Typography>{data}</Typography>
        </div>

     );
}
 
export default VerticalData;